import { MapProjectionName } from "./MapProjections"

export enum WorldRegionToProjection {
    Abkhazia = MapProjectionName.Asia,
    Afghanistan = MapProjectionName.Asia,
    "Akrotiri and Dhekelia" = MapProjectionName.Asia,
    Albania = MapProjectionName.Europe,
    Algeria = MapProjectionName.Africa,
    "American Samoa" = MapProjectionName.Oceania,
    Andorra = MapProjectionName.Europe,
    Angola = MapProjectionName.Africa,
    Anguilla = MapProjectionName.NorthAmerica,
    "Antigua and Barbuda" = MapProjectionName.NorthAmerica,
    Argentina = MapProjectionName.SouthAmerica,
    Armenia = MapProjectionName.Asia,
    Aruba = MapProjectionName.NorthAmerica,
    Australia = MapProjectionName.Oceania,
    Austria = MapProjectionName.Europe,
    "Austria-Hungary" = MapProjectionName.Europe,
    Azerbaijan = MapProjectionName.Asia,
    Baden = MapProjectionName.Europe,
    Bahamas = MapProjectionName.NorthAmerica,
    Bahrain = MapProjectionName.Asia,
    Bangladesh = MapProjectionName.Asia,
    Barbados = MapProjectionName.NorthAmerica,
    Bavaria = MapProjectionName.Europe,
    Belarus = MapProjectionName.Europe,
    Belgium = MapProjectionName.Europe,
    Belize = MapProjectionName.NorthAmerica,
    Benin = MapProjectionName.Africa,
    Bermuda = MapProjectionName.NorthAmerica,
    Bhutan = MapProjectionName.Asia,
    Bolivia = MapProjectionName.SouthAmerica,
    "Bonaire Sint Eustatius and Saba" = MapProjectionName.NorthAmerica,
    "Bosnia and Herzegovina" = MapProjectionName.Europe,
    Botswana = MapProjectionName.Africa,
    Brazil = MapProjectionName.SouthAmerica,
    "British Indian Ocean Territory" = MapProjectionName.Asia,
    "British Virgin Islands" = MapProjectionName.NorthAmerica,
    Brunei = MapProjectionName.Asia,
    Bulgaria = MapProjectionName.Europe,
    "Burkina Faso" = MapProjectionName.Africa,
    Burundi = MapProjectionName.Africa,
    Cambodia = MapProjectionName.Asia,
    Cameroon = MapProjectionName.Africa,
    Canada = MapProjectionName.NorthAmerica,
    "Cape Verde" = MapProjectionName.Africa,
    "Cayman Islands" = MapProjectionName.NorthAmerica,
    "Central African Republic" = MapProjectionName.Africa,
    Chad = MapProjectionName.Africa,
    "Channel Islands" = MapProjectionName.Europe,
    Chile = MapProjectionName.SouthAmerica,
    China = MapProjectionName.Asia,
    "Christmas Island" = MapProjectionName.Asia,
    "Cocos Islands" = MapProjectionName.Asia,
    Colombia = MapProjectionName.SouthAmerica,
    Comoros = MapProjectionName.Africa,
    Congo = MapProjectionName.Africa,
    "Cook Islands" = MapProjectionName.Oceania,
    "Costa Rica" = MapProjectionName.NorthAmerica,
    "Cote d'Ivoire" = MapProjectionName.Africa,
    Croatia = MapProjectionName.Europe,
    Cuba = MapProjectionName.NorthAmerica,
    Curacao = MapProjectionName.NorthAmerica,
    Cyprus = MapProjectionName.Europe,
    "Czechia" = MapProjectionName.Europe,
    Czechoslovakia = MapProjectionName.Europe,
    "Democratic Republic of Congo" = MapProjectionName.Africa,
    Denmark = MapProjectionName.Europe,
    Djibouti = MapProjectionName.Africa,
    Dominica = MapProjectionName.NorthAmerica,
    "Dominican Republic" = MapProjectionName.NorthAmerica,
    "East Germany" = MapProjectionName.Europe,
    Ecuador = MapProjectionName.SouthAmerica,
    Egypt = MapProjectionName.Africa,
    "El Salvador" = MapProjectionName.NorthAmerica,
    "Equatorial Guinea" = MapProjectionName.Africa,
    Eritrea = MapProjectionName.Africa,
    "Eritrea and Ethiopia" = MapProjectionName.Africa,
    Estonia = MapProjectionName.Europe,
    Ethiopia = MapProjectionName.Africa,
    "Faeroe Islands" = MapProjectionName.Europe,
    "Falkland Islands" = MapProjectionName.SouthAmerica,
    Fiji = MapProjectionName.Oceania,
    Finland = MapProjectionName.Europe,
    France = MapProjectionName.Europe,
    "French Guiana" = MapProjectionName.SouthAmerica,
    "French Polynesia" = MapProjectionName.Oceania,
    Gabon = MapProjectionName.Africa,
    Gambia = MapProjectionName.Africa,
    Georgia = MapProjectionName.Asia,
    Germany = MapProjectionName.Europe,
    Ghana = MapProjectionName.Africa,
    Gibraltar = MapProjectionName.Europe,
    Greece = MapProjectionName.Europe,
    Greenland = MapProjectionName.NorthAmerica,
    Grenada = MapProjectionName.NorthAmerica,
    Guadeloupe = MapProjectionName.NorthAmerica,
    Guam = MapProjectionName.Oceania,
    Guatemala = MapProjectionName.NorthAmerica,
    Guernsey = MapProjectionName.Europe,
    Guinea = MapProjectionName.Africa,
    "Guinea-Bissau" = MapProjectionName.Africa,
    Guyana = MapProjectionName.SouthAmerica,
    Haiti = MapProjectionName.NorthAmerica,
    Hanover = MapProjectionName.Europe,
    "Hesse Electoral" = MapProjectionName.Europe,
    "Hesse Grand Ducal" = MapProjectionName.Europe,
    Honduras = MapProjectionName.NorthAmerica,
    "Hong Kong" = MapProjectionName.Asia,
    Hungary = MapProjectionName.Europe,
    Iceland = MapProjectionName.Europe,
    India = MapProjectionName.Asia,
    Indonesia = MapProjectionName.Asia,
    Iran = MapProjectionName.Asia,
    Iraq = MapProjectionName.Asia,
    Ireland = MapProjectionName.Europe,
    "Isle of Man" = MapProjectionName.Europe,
    Israel = MapProjectionName.Asia,
    Italy = MapProjectionName.Europe,
    Jamaica = MapProjectionName.NorthAmerica,
    Japan = MapProjectionName.Asia,
    Jersey = MapProjectionName.Europe,
    Jordan = MapProjectionName.Asia,
    Kazakhstan = MapProjectionName.Asia,
    Kenya = MapProjectionName.Africa,
    Kiribati = MapProjectionName.Oceania,
    Kosovo = MapProjectionName.Europe,
    Kuwait = MapProjectionName.Asia,
    Kyrgyzstan = MapProjectionName.Asia,
    Laos = MapProjectionName.Asia,
    Latvia = MapProjectionName.Europe,
    Lebanon = MapProjectionName.Asia,
    Lesotho = MapProjectionName.Africa,
    Liberia = MapProjectionName.Africa,
    Libya = MapProjectionName.Africa,
    Liechtenstein = MapProjectionName.Europe,
    Lithuania = MapProjectionName.Europe,
    Luxembourg = MapProjectionName.Europe,
    Macao = MapProjectionName.Asia,
    "North Macedonia" = MapProjectionName.Europe,
    Madagascar = MapProjectionName.Africa,
    Malawi = MapProjectionName.Africa,
    Malaysia = MapProjectionName.Asia,
    Maldives = MapProjectionName.Asia,
    Mali = MapProjectionName.Africa,
    Malta = MapProjectionName.Europe,
    "Marshall Islands" = MapProjectionName.Oceania,
    Martinique = MapProjectionName.NorthAmerica,
    Mauritania = MapProjectionName.Africa,
    Mauritius = MapProjectionName.Africa,
    Mayotte = MapProjectionName.Africa,
    "Mecklenburg Schwerin" = MapProjectionName.Europe,
    Mexico = MapProjectionName.NorthAmerica,
    "Micronesia (country)" = MapProjectionName.Oceania,
    Modena = MapProjectionName.Europe,
    Moldova = MapProjectionName.Europe,
    Monaco = MapProjectionName.Europe,
    Mongolia = MapProjectionName.Asia,
    Montenegro = MapProjectionName.Europe,
    Montserrat = MapProjectionName.NorthAmerica,
    Morocco = MapProjectionName.Africa,
    Mozambique = MapProjectionName.Africa,
    Myanmar = MapProjectionName.Asia,
    "Nagorno-Karabakh" = MapProjectionName.Asia,
    Namibia = MapProjectionName.Africa,
    Nauru = MapProjectionName.Oceania,
    Nepal = MapProjectionName.Asia,
    Netherlands = MapProjectionName.Europe,
    "Netherlands Antilles" = MapProjectionName.NorthAmerica,
    "New Caledonia" = MapProjectionName.Oceania,
    "New Zealand" = MapProjectionName.Oceania,
    Nicaragua = MapProjectionName.NorthAmerica,
    Niger = MapProjectionName.Africa,
    Nigeria = MapProjectionName.Africa,
    Niue = MapProjectionName.Oceania,
    "Norfolk Island" = MapProjectionName.Oceania,
    "North Korea" = MapProjectionName.Asia,
    "Northern Cyprus" = MapProjectionName.Asia,
    "Northern Mariana Islands" = MapProjectionName.Oceania,
    Norway = MapProjectionName.Europe,
    Oman = MapProjectionName.Asia,
    Pakistan = MapProjectionName.Asia,
    Palau = MapProjectionName.Oceania,
    Palestine = MapProjectionName.Asia,
    Panama = MapProjectionName.NorthAmerica,
    "Papua New Guinea" = MapProjectionName.Oceania,
    Paraguay = MapProjectionName.SouthAmerica,
    Parma = MapProjectionName.Europe,
    Peru = MapProjectionName.SouthAmerica,
    Philippines = MapProjectionName.Asia,
    Pitcairn = MapProjectionName.Oceania,
    Poland = MapProjectionName.Europe,
    Portugal = MapProjectionName.Europe,
    "Puerto Rico" = MapProjectionName.NorthAmerica,
    Qatar = MapProjectionName.Asia,
    "Republic of Vietnam" = MapProjectionName.Asia,
    Reunion = MapProjectionName.Africa,
    Romania = MapProjectionName.Europe,
    Russia = MapProjectionName.Europe,
    Rwanda = MapProjectionName.Africa,
    "Saint Barthélemy" = MapProjectionName.NorthAmerica,
    "Saint Helena" = MapProjectionName.Africa,
    "Saint Kitts and Nevis" = MapProjectionName.NorthAmerica,
    "Saint Lucia" = MapProjectionName.NorthAmerica,
    "Saint Martin (French part)" = MapProjectionName.NorthAmerica,
    "Saint Pierre and Miquelon" = MapProjectionName.NorthAmerica,
    "Saint Vincent and the Grenadines" = MapProjectionName.NorthAmerica,
    Samoa = MapProjectionName.Oceania,
    "San Marino" = MapProjectionName.Europe,
    "Sao Tome and Principe" = MapProjectionName.Africa,
    "Saudi Arabia" = MapProjectionName.Asia,
    Saxony = MapProjectionName.Europe,
    Senegal = MapProjectionName.Africa,
    Serbia = MapProjectionName.Europe,
    "Serbia and Montenegro" = MapProjectionName.Europe,
    "Serbia excluding Kosovo" = MapProjectionName.Europe,
    Seychelles = MapProjectionName.Africa,
    "Sierra Leone" = MapProjectionName.Africa,
    Singapore = MapProjectionName.Asia,
    "Sint Maarten (Dutch part)" = MapProjectionName.NorthAmerica,
    Slovakia = MapProjectionName.Europe,
    Slovenia = MapProjectionName.Europe,
    "Solomon Islands" = MapProjectionName.Oceania,
    Somalia = MapProjectionName.Africa,
    Somaliland = MapProjectionName.Africa,
    "South Africa" = MapProjectionName.Africa,
    "South Georgia and the South Sandwich Islands" = MapProjectionName.SouthAmerica,
    "South Korea" = MapProjectionName.Asia,
    "South Ossetia" = MapProjectionName.Asia,
    "South Sudan" = MapProjectionName.Africa,
    Spain = MapProjectionName.Europe,
    "Sri Lanka" = MapProjectionName.Asia,
    Sudan = MapProjectionName.Africa,
    Suriname = MapProjectionName.SouthAmerica,
    "Svalbard and Jan Mayen" = MapProjectionName.Europe,
    Eswatini = MapProjectionName.Africa,
    Sweden = MapProjectionName.Europe,
    Switzerland = MapProjectionName.Europe,
    Syria = MapProjectionName.Asia,
    Taiwan = MapProjectionName.Asia,
    Tajikistan = MapProjectionName.Asia,
    Tanzania = MapProjectionName.Africa,
    Thailand = MapProjectionName.Asia,
    Timor = MapProjectionName.Asia,
    Togo = MapProjectionName.Africa,
    Tokelau = MapProjectionName.Oceania,
    Tonga = MapProjectionName.Oceania,
    Transnistria = MapProjectionName.Europe,
    "Trinidad and Tobago" = MapProjectionName.NorthAmerica,
    Tunisia = MapProjectionName.Africa,
    Turkey = MapProjectionName.Asia,
    Turkmenistan = MapProjectionName.Asia,
    "Turks and Caicos Islands" = MapProjectionName.NorthAmerica,
    Tuscany = MapProjectionName.Europe,
    Tuvalu = MapProjectionName.Oceania,
    "Two Sicilies" = MapProjectionName.Europe,
    USSR = MapProjectionName.Europe,
    Uganda = MapProjectionName.Africa,
    Ukraine = MapProjectionName.Europe,
    "United Arab Emirates" = MapProjectionName.Asia,
    "United Kingdom" = MapProjectionName.Europe,
    "United Korea" = MapProjectionName.Asia,
    "United States" = MapProjectionName.NorthAmerica,
    "United States Minor Outlying Islands" = MapProjectionName.Oceania,
    "United States Virgin Islands" = MapProjectionName.NorthAmerica,
    Uruguay = MapProjectionName.SouthAmerica,
    Uzbekistan = MapProjectionName.Asia,
    Vanuatu = MapProjectionName.Oceania,
    Vatican = MapProjectionName.Europe,
    Venezuela = MapProjectionName.SouthAmerica,
    Vietnam = MapProjectionName.Asia,
    "Wallis and Futuna" = MapProjectionName.Oceania,
    "West Germany" = MapProjectionName.Europe,
    "Western Sahara" = MapProjectionName.Africa,
    Wuerttemburg = MapProjectionName.Europe,
    Yemen = MapProjectionName.Asia,
    "Yemen Arab Republic" = MapProjectionName.Asia,
    "Yemen People's Republic" = MapProjectionName.Asia,
    Yugoslavia = MapProjectionName.Europe,
    Zambia = MapProjectionName.Africa,
    Zanzibar = MapProjectionName.Africa,
    Zimbabwe = MapProjectionName.Africa,
    "Åland Islands" = MapProjectionName.Europe,
}

export type WorldRegionName = keyof typeof WorldRegionToProjection
