// todo: can we reuse this elsewhere?
export const populationMap: { [country: string]: number } = {
    Afghanistan: 38928341,
    Africa: 1340598113,
    Albania: 2877800,
    Algeria: 43851043,
    "American Samoa": 55197,
    Andorra: 77265,
    Angola: 32866268,
    Anguilla: 15002,
    "Antigua and Barbuda": 97928,
    Argentina: 45195777,
    Armenia: 2963234,
    Aruba: 106766,
    Asia: 4641054786,
    Australia: 25499881,
    Austria: 9006400,
    Azerbaijan: 10139175,
    Bahamas: 393248,
    Bahrain: 1701583,
    Bangladesh: 164689383,
    Barbados: 287371,
    Belarus: 9449321,
    Belgium: 11589616,
    Belize: 397621,
    Benin: 12123198,
    Bermuda: 62273,
    Bhutan: 771612,
    Bolivia: 11673029,
    "Bonaire Sint Eustatius and Saba": 26221,
    "Bosnia and Herzegovina": 3280815,
    Botswana: 2351625,
    Brazil: 212559409,
    "British Virgin Islands": 30237,
    Brunei: 437483,
    Bulgaria: 6948445,
    "Burkina Faso": 20903278,
    Burundi: 11890781,
    Cambodia: 16718971,
    Cameroon: 26545864,
    Canada: 37742157,
    "Cape Verde": 555988,
    Caribbean: 43532374,
    "Cayman Islands": 65720,
    "Central African Republic": 4829764,
    "Central America": 179670186,
    "Central Asia": 74338926,
    "Central and Southern Asia": 2014708531,
    Chad: 16425859,
    "Channel Islands": 173859,
    Chile: 19116209,
    China: 1439323774,
    Colombia: 50882884,
    Comoros: 869595,
    Congo: 5518092,
    "Cook Islands": 17564,
    "Costa Rica": 5094114,
    "Cote d'Ivoire": 26378275,
    Croatia: 4105268,
    Cuba: 11326616,
    Curacao: 164100,
    Cyprus: 1207361,
    Czechia: 10708982,
    "Democratic Republic of Congo": 89561404,
    Denmark: 5792203,
    Djibouti: 988002,
    Dominica: 71991,
    "Dominican Republic": 10847904,
    "Eastern Africa": 445405578,
    "Eastern Europe": 293013210,
    Ecuador: 17643060,
    Egypt: 102334403,
    "El Salvador": 6486201,
    "Equatorial Guinea": 1402985,
    Eritrea: 3546427,
    Estonia: 1326539,
    Ethiopia: 114963583,
    Europe: 747636045,
    "Faeroe Islands": 48865,
    "Falkland Islands": 3483,
    Fiji: 896444,
    Finland: 5540718,
    France: 65273512,
    "French Guiana": 298682,
    "French Polynesia": 280904,
    Gabon: 2225728,
    Gambia: 2416664,
    Georgia: 3989175,
    Germany: 83783945,
    Ghana: 31072945,
    Gibraltar: 33691,
    Greece: 10423056,
    Greenland: 56772,
    Grenada: 112519,
    Guadeloupe: 400127,
    Guam: 168783,
    Guatemala: 17915567,
    Guernsey: 67052,
    Guinea: 13132792,
    "Guinea-Bissau": 1967998,
    Guyana: 786559,
    Haiti: 11402533,
    "High-income countries": 1263092934,
    Honduras: 9904608,
    "Hong Kong": 7496988,
    Hungary: 9660350,
    Iceland: 341250,
    India: 1380004385,
    Indonesia: 273523621,
    International: 7794798729,
    Iran: 83992953,
    Iraq: 40222503,
    Ireland: 4937796,
    "Isle of Man": 85032,
    Israel: 8655541,
    Italy: 60461828,
    Jamaica: 2961161,
    Japan: 126476458,
    Jersey: 101073,
    Jordan: 10203140,
    Kazakhstan: 18776707,
    Kenya: 53771300,
    Kiribati: 119446,
    Kosovo: 1932774,
    Kuwait: 4270563,
    Kyrgyzstan: 6524191,
    "Land-locked Developing Countries (LLDC)": 533143398,
    Laos: 7275556,
    "Latin America and the Caribbean": 653962332,
    Latvia: 1886202,
    "Least developed countries": 1057438163,
    Lebanon: 6825442,
    Lesotho: 2142252,
    "Less developed regions": 6521494468,
    Liberia: 5057677,
    Libya: 6871287,
    Liechtenstein: 38137,
    Lithuania: 2722291,
    "Low-income countries": 775710612,
    "Lower-middle-income countries": 3098235284,
    Luxembourg: 625976,
    Macao: 649342,
    "North Macedonia": 2083380,
    Madagascar: 27691019,
    Malawi: 19129955,
    Malaysia: 32365998,
    Maldives: 540542,
    Mali: 20250834,
    Malta: 441539,
    "Marshall Islands": 59194,
    Martinique: 375265,
    Mauritania: 4649660,
    Mauritius: 1271767,
    Mayotte: 272813,
    Melanesia: 11122990,
    Mexico: 128932753,
    Micronesia: 548927,
    "Micronesia (country)": 115021,
    "Middle Africa": 179595125,
    "Middle-income countries": 5753051615,
    Moldova: 4033963,
    Monaco: 39244,
    Mongolia: 3278292,
    Montenegro: 628062,
    Montserrat: 4999,
    "More developed regions": 1273304261,
    Morocco: 36910558,
    Mozambique: 31255435,
    Myanmar: 54409794,
    Namibia: 2540916,
    Nauru: 10834,
    Nepal: 29136808,
    Netherlands: 17134873,
    "New Caledonia": 285491,
    "New Zealand": 4822233,
    Nicaragua: 6624554,
    Niger: 24206636,
    Nigeria: 206139587,
    Niue: 1618,
    "North America": 368869644,
    "North Korea": 25778815,
    "Northern Africa": 246232508,
    "Northern Africa and Western Asia": 525869282,
    "Northern America": 368869644,
    "Northern Europe": 106261271,
    "Northern Mariana Islands": 57557,
    Norway: 5421242,
    Oceania: 42677809,
    Oman: 5106622,
    Pakistan: 220892331,
    Palau: 18092,
    Palestine: 5101416,
    Panama: 4314768,
    "Papua New Guinea": 8947027,
    Paraguay: 7132530,
    Peru: 32971846,
    Philippines: 109581085,
    Poland: 37846605,
    Polynesia: 683778,
    Portugal: 10196707,
    "Puerto Rico": 2860840,
    Qatar: 2881060,
    Reunion: 895308,
    Romania: 19237682,
    Russia: 145934460,
    Rwanda: 12952209,
    "Saint Barthlemy": 9885,
    "Saint Helena": 6071,
    "Saint Kitts and Nevis": 53192,
    "Saint Lucia": 183629,
    "Saint Martin (French part)": 38659,
    "Saint Pierre and Miquelon": 5795,
    "Saint Vincent and the Grenadines": 110947,
    Samoa: 198410,
    "San Marino": 33938,
    "Sao Tome and Principe": 219161,
    "Saudi Arabia": 34813867,
    Senegal: 16743930,
    Serbia: 6804596,
    "Serbia (including Kosovo)": 8737370,
    Seychelles: 98340,
    "Sierra Leone": 7976985,
    Singapore: 5850343,
    "Sint Maarten (Dutch part)": 42882,
    Slovakia: 5459643,
    Slovenia: 2078932,
    "Small Island Developing States (SIDS)": 72076098,
    "Solomon Islands": 686878,
    Somalia: 15893219,
    "South Africa": 59308690,
    "South America": 430759772,
    "South Korea": 51269183,
    "South Sudan": 11193729,
    "South-Eastern Asia": 668619854,
    "Southern Africa": 67503647,
    "Southern Asia": 1940369605,
    "Southern Europe": 152215243,
    Spain: 46754783,
    "Sri Lanka": 21413250,
    "Sub-Saharan Africa": 1094365605,
    Sudan: 43849269,
    Suriname: 586634,
    Eswatini: 1160164,
    Sweden: 10099270,
    Switzerland: 8654618,
    Syria: 17500657,
    Taiwan: 23816775,
    Tajikistan: 9537642,
    Tanzania: 59734213,
    Thailand: 69799978,
    Timor: 1318442,
    Togo: 8278737,
    Tokelau: 1350,
    Tonga: 105697,
    "Trinidad and Tobago": 1399491,
    Tunisia: 11818618,
    Turkey: 84339067,
    Turkmenistan: 6031187,
    "Turks and Caicos Islands": 38718,
    Tuvalu: 11792,
    Uganda: 45741000,
    Ukraine: 43733759,
    "United Arab Emirates": 9890400,
    "United Kingdom": 67886004,
    "United States": 331002647,
    "United States Virgin Islands": 104423,
    "Upper-middle-income countries": 2654816331,
    Uruguay: 3473727,
    Uzbekistan: 33469199,
    Vanuatu: 307150,
    Vatican: 809,
    Venezuela: 28435943,
    Vietnam: 97338583,
    "Wallis and Futuna": 11246,
    "Western Africa": 401861255,
    "Western Asia": 279636774,
    "Western Europe": 196146321,
    "Western Sahara": 597330,
    World: 7794798729,
    Yemen: 29825968,
    Zambia: 18383956,
    Zimbabwe: 14862927,
}
